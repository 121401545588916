<template>
    <modal ref="modalMoverArchivo" titulo="Mover archivo" guardar tamano="modal-md" adicional="Mover" @adicional="moveFile">
        <ValidationObserver ref="form">
            <div class="row mx-0 mt-2">
                <div class="col">
                    <p class="f-13 mx-2">Selecciona la carpeta destino de este archivo</p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Carpeta">
                        <el-select 
                        v-model="idFolder" 
                        class="w-100" 
                        size="small" 
                        placeholder="Seleccionar" 
                        filterable 
                        :remote-method="filterFolders"
                        :loading="loading"
                        @change="folderSelected"
                        >
                            <el-option
                            v-for="folder in foldersFilter"
                            :key="folder.id"
                            :label="folder.nombre"
                            :value="folder.id"
                            />
                        </el-select>
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
import Service from '~/services/biblioteca/archivos';

export default {
    data(){
        return {
            loading: false,
            idFile: null,
            idFolder: null,
            folders: [],
            foldersFilter: [],
        }
    },

    computed:{
        idCarpetaActual(){
            return Number(this.$route.params.idCarpeta);
        }
    },

    methods:{
        async toggle(idFile){
            await this.limpiar();
            this.idFile = idFile;
            this.getCarpetas();
            this.$refs.modalMoverArchivo.toggle();
        },

        async getCarpetas(){
            try {
                const { data } = await Service.getCarpetas();
                let filter = [];
                data.data.map((e) => {
                    if(e.id !== this.idCarpetaActual){
                        filter.push(e);
                    }
                });
                this.folders = filter;
                this.foldersFilter = filter;
            } catch(e){
                this.errorCatch(e);
            }
        },

        async folderSelected(){
            if(Number(this.idCarpetaActual) === Number(this.idFolder)){
                this.$notify({
                    title: 'Alerta',
                    message: 'El archivo ya se encuentra en la carpeta seleccionada',
                    type: 'warning'
              	});
                this.idFolder = null;
            }
        },

        filterFolders(query){
            if (query !== ''){
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.foldersFilter = this.foldersFilter.filter(item => {
                        return item.nombre.toLowerCase()
                            .indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.foldersFilter = this.folders;
            }
        },

        async moveFile(){
            try {
                let valid = await this.$refs.form.validate();
                if(!valid)return;
				
                const payload = {
                    carpeta: this.idFolder
                };

                const {data} = await Service.putMoveFile(this.idFile, payload);
                if(data.success){
                    this.$notify({
                        title: 'Mensaje',
                        message: data.mensaje,
                        type: 'success'
              		});
                    this.$emit('update', this.idFile);
                    this.$refs.modalMoverArchivo.toggle();
                }
            } catch(e){
                this.errorCatch(e);
            }
        },

        async limpiar(){
            this.loading = false;
            this.idFile = null;
            this.idFolder = null;
            this.folders = [];
            this.foldersFilter = [];
            this.$refs.form.reset();
        },
    }
}
</script>
<style lang="scss" scoped>

</style>